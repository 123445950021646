@import "https://rsms.me/inter/inter.css";
.leaflet-pane, .leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-tile-container, .leaflet-pane > svg, .leaflet-pane > canvas, .leaflet-zoom-box, .leaflet-image-layer, .leaflet-layer {
  position: absolute;
  top: 0;
  left: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile, .leaflet-marker-icon, .leaflet-marker-shadow {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.leaflet-tile::selection {
  background: none;
}

.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

.leaflet-safari .leaflet-tile-container {
  -webkit-transform-origin: 0 0;
  width: 1600px;
  height: 1600px;
}

.leaflet-marker-icon, .leaflet-marker-shadow {
  display: block;
}

.leaflet-container .leaflet-overlay-pane svg {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container .leaflet-marker-pane img, .leaflet-container .leaflet-shadow-pane img, .leaflet-container .leaflet-tile-pane img, .leaflet-container img.leaflet-image-layer, .leaflet-container .leaflet-tile {
  width: auto;
  padding: 0;
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: #33b5e566;
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  box-sizing: border-box;
  z-index: 800;
  width: 0;
  height: 0;
}

.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url("#default#VML");
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  position: relative;
}

.leaflet-top, .leaflet-bottom {
  z-index: 1000;
  pointer-events: none;
  position: absolute;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity .2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  transform-origin: 0 0;
}

svg.leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform .25s cubic-bezier(0, 0, .25, 1);
  -moz-transition: -moz-transform .25s cubic-bezier(0, 0, .25, 1);
  transition: transform .25s cubic-bezier(0, 0, .25, 1);
}

.leaflet-zoom-anim .leaflet-tile, .leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair, .leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane, .leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab, .leaflet-dragging .leaflet-grab .leaflet-interactive, .leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}

.leaflet-marker-icon, .leaflet-marker-shadow, .leaflet-image-layer, .leaflet-pane > svg path, .leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-container {
  outline-offset: 1px;
  background: #ddd;
}

.leaflet-container a {
  color: #0078a8;
}

.leaflet-zoom-box {
  background: #ffffff80;
  border: 2px dotted #38f;
}

.leaflet-container {
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: .75rem;
  line-height: 1.5;
}

.leaflet-bar {
  border-radius: 4px;
  box-shadow: 0 1px 5px #000000a6;
}

.leaflet-bar a {
  text-align: center;
  color: #000;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  text-decoration: none;
  display: block;
}

.leaflet-bar a, .leaflet-control-layers-toggle {
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover, .leaflet-bar a:focus {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom: none;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  color: #bbb;
  background-color: #f4f4f4;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  text-indent: 1px;
  font: bold 18px Lucida Console, Monaco, monospace;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

.leaflet-control-layers {
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 5px #0006;
}

.leaflet-control-layers-toggle {
  background-image: url("https://unpkg.com/leaflet@1.9.3/dist/images/layers.png");
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url("https://unpkg.com/leaflet@1.9.3/dist/images/layers-2x.png");
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list, .leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  color: #333;
  background: #fff;
  padding: 6px 10px 6px 6px;
}

.leaflet-control-layers-scrollbar {
  padding-right: 5px;
  overflow: hidden scroll;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  font-size: 1.08333em;
  display: block;
}

.leaflet-control-layers-separator {
  border-top: 1px solid #ddd;
  height: 0;
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url("https://unpkg.com/leaflet@1.9.3/dist/images/marker-icon.png");
}

.leaflet-container .leaflet-control-attribution {
  background: #fffc;
  margin: 0;
}

.leaflet-control-attribution, .leaflet-control-scale-line {
  color: #333;
  padding: 0 5px;
  line-height: 1.4;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover, .leaflet-control-attribution a:focus {
  text-decoration: underline;
}

.leaflet-attribution-flag {
  width: 1em;
  height: .6669em;
  vertical-align: baseline !important;
  display: inline !important;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  white-space: nowrap;
  box-sizing: border-box;
  text-shadow: 1px 1px #fff;
  background: #fffc;
  border: 2px solid #777;
  border-top: none;
  padding: 2px 5px 1px;
  line-height: 1.1;
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution, .leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  background-clip: padding-box;
  border: 2px solid #0003;
}

.leaflet-popup {
  text-align: center;
  filter: drop-shadow(0 3px 14px #0006);
  position: absolute;
}

.leaflet-popup.updating-position {
  transition: transform .2s ease-in-out !important;
}

.leaflet-popup-content-wrapper {
  text-align: left;
  border-radius: 12px;
  width: 280px;
  padding: 0;
}

.leaflet-popup-content {
  min-height: 1px;
  font-size: 1.08333em;
  line-height: 1.3;
}

.leaflet-popup-tip-container {
  pointer-events: none;
  width: 40px;
  height: 20px;
  margin-top: -1px;
  margin-left: -20px;
  position: absolute;
  left: 50%;
  overflow: hidden;
}

.leaflet-popup-tip-container.north-center {
  bottom: -20px;
}

.leaflet-popup-tip-container.north-east {
  bottom: -20px;
  left: 0;
  transform: scaleY(2)scaleX(1.25)skewY(0)skewX(-45deg);
}

.leaflet-popup-tip-container.north-west {
  bottom: -20px;
  left: 100%;
  transform: translateX(-1px)scaleY(2)scaleX(1.25)skewY(0)skewX(45deg);
}

.leaflet-popup-tip-container.south-center {
  top: -19px;
  transform: scaleY(-1);
}

.leaflet-popup-tip-container.south-east {
  top: -19px;
  left: 0;
  transform: scaleY(-2)scaleX(1.25)skewY(0)skewX(-45deg);
}

.leaflet-popup-tip-container.south-west {
  top: -19px;
  left: 100%;
  transform: translateX(-1px)scaleY(-2)scaleX(1.25)skewY(0)skewX(45deg);
}

.leaflet-popup-tip {
  pointer-events: auto;
  width: 17px;
  height: 17px;
  margin: -10px auto 0;
  padding: 1px;
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  color: #333;
  background: #fff;
}

.leaflet-container a.leaflet-popup-close-button {
  text-align: center;
  color: #757575;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding-bottom: .5px;
  padding-left: .5px;
  font: 16px / 24px monospace;
  text-decoration: none;
  display: flex;
  position: absolute;
  top: 4px;
  right: 4px;
}

.leaflet-container a.leaflet-popup-close-button:hover, .leaflet-container a.leaflet-popup-close-button:focus {
  color: #585858;
}

.leaflet-popup-scrolled {
  overflow: auto;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  -ms-zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  width: 24px;
  filter: progid:DXImageTransform. Microsoft. Matrix(M11= .707107, M12= .707107, M21= -.707107, M22= .707107);
  margin: 0 auto;
}

.leaflet-oldie .leaflet-control-zoom, .leaflet-oldie .leaflet-control-layers, .leaflet-oldie .leaflet-popup-content-wrapper, .leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

.leaflet-tooltip {
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 6px;
  position: absolute;
  box-shadow: 0 1px 3px #0006;
}

.leaflet-tooltip.leaflet-interactive {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before, .leaflet-tooltip-bottom:before, .leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  pointer-events: none;
  content: "";
  background: none;
  border: 6px solid #0000;
  position: absolute;
}

.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before, .leaflet-tooltip-top:before {
  margin-left: -6px;
  left: 50%;
}

.leaflet-tooltip-top:before {
  border-top-color: #fff;
  margin-bottom: -12px;
  bottom: 0;
}

.leaflet-tooltip-bottom:before {
  border-bottom-color: #fff;
  margin-top: -12px;
  margin-left: -6px;
  top: 0;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before, .leaflet-tooltip-right:before {
  margin-top: -6px;
  top: 50%;
}

.leaflet-tooltip-left:before {
  border-left-color: #fff;
  margin-right: -12px;
  right: 0;
}

.leaflet-tooltip-right:before {
  border-right-color: #fff;
  margin-left: -12px;
  left: 0;
}

@media print {
  .leaflet-control {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

.leaflet-container {
  object-fit: cover;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.leaflet-popup {
  bottom: 0 !important;
}

.leaflet-popup-content {
  width: 100% !important;
}

.leaflet-popup-content h3 {
  margin-bottom: 8px;
}

.leaflet-popup-content h4 {
  margin-top: .5rem;
  font-weight: 600;
}

.leaflet-popup-content p {
  margin: 0;
}

.leaflet-popup-content .detail-link {
  align-items: center;
  display: flex;
}

.leaflet-popup-content .spacer {
  height: .5rem;
}

.leaflet-left .leaflet-control {
  margin-left: 1rem;
}

.leaflet-right .leaflet-control {
  margin-right: 1rem;
}

.leaflet-top .leaflet-control {
  margin-top: 1rem;
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border-width: 1px;
}

.cluster-spiderfied {
  --tw-bg-opacity: .25 !important;
  width: 150px !important;
  height: 150px !important;
  margin-top: -75px !important;
  margin-left: -75px !important;
}

.leaflet-container.bBox .leaflet-draw {
  display: none;
}

canvas {
  opacity: .9999;
}

.leaflet-draw-section {
  position: relative;
}

.leaflet-draw-toolbar {
  margin-top: 12px;
}

.leaflet-draw-toolbar-top {
  margin-top: 0;
}

.leaflet-draw-toolbar-notop a:first-child {
  border-top-right-radius: 0;
}

.leaflet-draw-toolbar-nobottom a:last-child {
  border-bottom-right-radius: 0;
}

.leaflet-draw-toolbar a {
  background-image: url("spritesheet.4e9b5df2.png");
  background-repeat: no-repeat;
  background-size: 300px 30px;
  background-clip: padding-box;
}

.leaflet-retina .leaflet-draw-toolbar a {
  background-image: url("spritesheet-2x.76a70f8a.png");
}

.leaflet-draw a {
  text-align: center;
  text-decoration: none;
  display: block;
}

.leaflet-draw a .sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.leaflet-draw-actions {
  white-space: nowrap;
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: 0;
  left: 26px;
}

.leaflet-touch .leaflet-draw-actions {
  left: 32px;
}

.leaflet-right .leaflet-draw-actions {
  left: auto;
  right: 26px;
}

.leaflet-touch .leaflet-right .leaflet-draw-actions {
  left: auto;
  right: 32px;
}

.leaflet-draw-actions li {
  display: inline-block;
}

.leaflet-draw-actions li:first-child a {
  border-left: 0;
}

.leaflet-draw-actions li:last-child a {
  border-radius: 0 4px 4px 0;
}

.leaflet-right .leaflet-draw-actions li:last-child a {
  border-radius: 0;
}

.leaflet-right .leaflet-draw-actions li:first-child a {
  border-radius: 4px 0 0 4px;
}

.leaflet-draw-actions a {
  color: #fff;
  background-color: #919187;
  border-left: 1px solid #aaa;
  height: 28px;
  padding-left: 10px;
  padding-right: 10px;
  font: 11px / 28px Helvetica Neue, Arial, Helvetica, sans-serif;
  text-decoration: none;
}

.leaflet-touch .leaflet-draw-actions a {
  height: 30px;
  font-size: 12px;
  line-height: 30px;
}

.leaflet-draw-actions-bottom {
  margin-top: 0;
}

.leaflet-draw-actions-top {
  margin-top: 1px;
}

.leaflet-draw-actions-top a, .leaflet-draw-actions-bottom a {
  height: 27px;
  line-height: 27px;
}

.leaflet-draw-actions a:hover {
  background-color: #a0a098;
}

.leaflet-draw-actions-top.leaflet-draw-actions-bottom a {
  height: 26px;
  line-height: 26px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: -2px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
  background-position: 0 -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -31px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
  background-position: -29px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -62px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-rectangle {
  background-position: -60px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -92px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
  background-position: -90px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -122px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-marker {
  background-position: -120px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -273px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circlemarker {
  background-position: -271px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -152px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit {
  background-position: -150px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -182px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
  background-position: -180px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -212px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
  background-position: -210px -1px;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -242px -2px;
}

.leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
  background-position: -240px -2px;
}

.leaflet-mouse-marker {
  cursor: crosshair;
  background-color: #fff;
}

.leaflet-draw-tooltip {
  color: #fff;
  visibility: hidden;
  white-space: nowrap;
  z-index: 6;
  background: #00000080;
  border: 1px solid #0000;
  border-radius: 4px;
  margin-top: -21px;
  margin-left: 20px;
  padding: 4px 8px;
  font: 12px / 18px Helvetica Neue, Arial, Helvetica, sans-serif;
  position: absolute;
}

.leaflet-draw-tooltip:before {
  content: "";
  border-top: 6px solid #0000;
  border-bottom: 6px solid #0000;
  border-right: 6px solid #00000080;
  position: absolute;
  top: 7px;
  left: -7px;
}

.leaflet-error-draw-tooltip {
  color: #b94a48;
  background-color: #f2dede;
  border: 1px solid #e6b6bd;
}

.leaflet-error-draw-tooltip:before {
  border-right-color: #e6b6bd;
}

.leaflet-draw-tooltip-single {
  margin-top: -12px;
}

.leaflet-draw-tooltip-subtext {
  color: #f8d5e4;
}

.leaflet-draw-guide-dash {
  opacity: .6;
  width: 5px;
  height: 5px;
  font-size: 1%;
  position: absolute;
}

.leaflet-edit-marker-selected {
  box-sizing: content-box;
  background-color: #fe57a11a;
  border: 4px dashed #fe57a199;
  border-radius: 4px;
}

.leaflet-edit-move {
  cursor: move;
}

.leaflet-edit-resize {
  cursor: pointer;
}

.leaflet-oldie .leaflet-draw-toolbar {
  border: 1px solid #999;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0 -10px;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.slick-slide > div {
  margin: 0 10px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev, .slick-next {
  cursor: pointer;
  color: #0000;
  background: none;
  border: none;
  outline: none;
  width: 20px;
  height: 20px;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  color: #0000;
  background: none;
  outline: none;
}

.slick-prev:hover:before, .slick-prev:focus:before, .slick-next:hover:before, .slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: slick;
  font-size: 20px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
  position: absolute;
  bottom: -25px;
}

.slick-dots li {
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  position: relative;
}

.slick-dots li button {
  cursor: pointer;
  color: #0000;
  background: none;
  border: 0;
  outline: none;
  width: 14px;
  height: 14px;
  padding: 5px;
  font-size: 0;
  line-height: 0;
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  content: "•";
  text-align: center;
  opacity: .25;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 20px;
  height: 20px;
  font-family: slick;
  font-size: 26px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: #000;
}

.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select {
  align-items: center;
  display: flex;
}

.react-datepicker__navigation, .react-datepicker__navigation--previous {
  top: 5px;
}

.react-datepicker__current-month, .react-datepicker__current-month--hasYearDropdown, .react-datepicker__current-month--hasMonthDropdown {
  margin-top: -4px;
  margin-bottom: 4px;
}

.react-datepicker-popper, .month-picker {
  width: 220px;
}

.month-picker .react-datepicker__month-text {
  width: 50px;
}

.month-picker .react-datepicker__navigation, .react-datepicker__navigation--previous {
  top: 9px;
}

.react-datepicker__month-text--keyboard-selected, .react-datepicker__day--keyboard-selected {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
  background-color: #0000;
  font-weight: 400;
}

.react-datepicker__day--today {
  font-weight: 600;
}

.react-datepicker__month-text:hover, .react-datepicker__day:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 44, 66, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.slider::-webkit-slider-thumb {
  -ms-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(130, 44, 66, var(--tw-bg-opacity, 1));
  cursor: pointer;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.slider::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 44, 66, var(--tw-bg-opacity, 1));
  cursor: pointer;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.disabled-slider::-webkit-slider-thumb {
  -ms-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.disabled-slider::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
  cursor: not-allowed;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.custom-slider::-webkit-slider-thumb {
  -ms-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(193, 99, 55, var(--tw-bg-opacity, 1));
  cursor: pointer;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.custom-slider::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 99, 55, var(--tw-bg-opacity, 1));
  cursor: pointer;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.disabled-custom-slider::-webkit-slider-thumb {
  -ms-appearance: none;
  appearance: none;
  --tw-bg-opacity: 1;
  background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.disabled-custom-slider::-moz-range-thumb {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
  cursor: not-allowed;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
}

.range-value {
  position: absolute;
  top: -50%;
}

.range-value span {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 220, 220, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
  text-align: center;
  border-radius: 6px;
  width: 34px;
  height: 24px;
  font-size: 13px;
  line-height: 24px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}

.range-value span:before {
  content: "";
  border-top: 10px solid #e0dcdc;
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  width: 0;
  height: 0;
  margin-top: -1px;
  margin-left: -5px;
  position: absolute;
  top: 100%;
  left: 50%;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: InterVariable, Inter, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

[multiple], textarea, select, [type="text"], input:where(:not([type])), [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"] {
  -ms-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[multiple]:focus, textarea:focus, select:focus, [type="text"]:focus, input:where(:not([type])):focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  print-color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple], [size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  print-color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -ms-appearance: none;
  appearance: none;
  print-color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="checkbox"]:checked {
    -ms-appearance: auto;
    appearance: auto;
  }
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active) {
  [type="radio"]:checked {
    -ms-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media (forced-colors: active) {
  [type="checkbox"]:indeterminate {
    -ms-appearance: auto;
    appearance: auto;
  }
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px solid buttontext;
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.visible {
  visibility: visible;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.-inset-2\.5 {
  inset: -.625rem;
}

.-inset-px {
  inset: -1px;
}

.inset-0 {
  inset: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.\!top-0 {
  top: 0 !important;
}

.-bottom-2 {
  bottom: -.5rem;
}

.-bottom-3 {
  bottom: -.75rem;
}

.-left-3 {
  left: -.75rem;
}

.-left-52 {
  left: -13rem;
}

.-right-3 {
  right: -.75rem;
}

.-right-5 {
  right: -1.25rem;
}

.-top-3 {
  top: -.75rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-0 {
  left: 0;
}

.left-2 {
  left: .5rem;
}

.left-4 {
  left: 1rem;
}

.right-0 {
  right: 0;
}

.right-1\/2 {
  right: 50%;
}

.right-1\/4 {
  right: 25%;
}

.right-2 {
  right: .5rem;
}

.right-3 {
  right: .75rem;
}

.right-4 {
  right: 1rem;
}

.right-8 {
  right: 2rem;
}

.right-\[4px\] {
  right: 4px;
}

.top-0 {
  top: 0;
}

.top-1\/4 {
  top: 25%;
}

.top-2 {
  top: .5rem;
}

.top-3 {
  top: .75rem;
}

.top-4 {
  top: 1rem;
}

.top-8 {
  top: 2rem;
}

.top-\[0\.43rem\] {
  top: .43rem;
}

.top-\[4px\] {
  top: 4px;
}

.\!z-50 {
  z-index: 50 !important;
}

.-z-10 {
  z-index: -10;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1000\] {
  z-index: 1000;
}

.z-\[100\] {
  z-index: 100;
}

.z-\[150\] {
  z-index: 150;
}

.z-\[15\] {
  z-index: 15;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-my-\[6\.5px\] {
  margin-top: -6.5px;
  margin-bottom: -6.5px;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.\!mt-0 {
  margin-top: 0 !important;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-ml-px {
  margin-left: -1px;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.ml-\[21px\] {
  margin-left: 21px;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-\[0\.05rem\] {
  margin-right: .05rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[6px\] {
  margin-top: 6px;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-6 {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.\!flex {
  display: flex !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-11 {
  width: 2.75rem;
  height: 2.75rem;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-7 {
  width: 1.75rem;
  height: 1.75rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-\[1\.1rem\] {
  width: 1.1rem;
  height: 1.1rem;
}

.size-\[15px\] {
  width: 15px;
  height: 15px;
}

.size-full {
  width: 100%;
  height: 100%;
}

.\!h-3 {
  height: .75rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-auto {
  height: auto !important;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-4 {
  height: 1rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-\[14px\] {
  height: 14px;
}

.h-\[150mm\] {
  height: 150mm;
}

.h-\[152px\] {
  height: 152px;
}

.h-\[17\.5px\] {
  height: 17.5px;
}

.h-\[175px\] {
  height: 175px;
}

.h-\[19rem\] {
  height: 19rem;
}

.h-\[23px\] {
  height: 23px;
}

.h-\[24px\] {
  height: 24px;
}

.h-\[30px\] {
  height: 30px;
}

.h-\[450px\] {
  height: 450px;
}

.h-\[48px\] {
  height: 48px;
}

.h-\[560px\] {
  height: 560px;
}

.h-\[7\.5rem\] {
  height: 7.5rem;
}

.h-\[calc\(100\%-10\.5rem\)\] {
  height: calc(100% - 10.5rem);
}

.h-\[calc\(100\%-3\.5rem\)\] {
  height: calc(100% - 3.5rem);
}

.h-\[calc\(100\%-7rem\)\] {
  height: calc(100% - 7rem);
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-\[18px\] {
  max-height: 18px;
}

.max-h-\[32rem\] {
  max-height: 32rem;
}

.max-h-\[36px\] {
  max-height: 36px;
}

.max-h-\[70\%\] {
  max-height: 70%;
}

.max-h-\[calc\(100\%-7rem\)\] {
  max-height: calc(100% - 7rem);
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen-66 {
  max-height: 66vh;
}

.min-h-0 {
  min-height: 0;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-\[41px\] {
  min-height: 41px;
}

.min-h-\[calc\(100vh-3\.5rem\)\] {
  min-height: calc(100vh - 3.5rem);
}

.min-h-full {
  min-height: 100%;
}

.\!w-5 {
  width: 1.25rem !important;
}

.\!w-\[1\.8rem\] {
  width: 1.8rem !important;
}

.\!w-\[14px\] {
  width: 14px !important;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/5 {
  width: 60%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-\[10\%\] {
  width: 10%;
}

.w-\[11rem\] {
  width: 11rem;
}

.w-\[200mm\] {
  width: 200mm;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[208px\] {
  width: 208px;
}

.w-\[26px\] {
  width: 26px;
}

.w-\[280px\] {
  width: 280px;
}

.w-\[360px\] {
  width: 360px;
}

.w-\[416px\] {
  width: 416px;
}

.w-\[calc\(100vw-5rem\)\] {
  width: calc(100vw - 5rem);
}

.w-\[min\(100\%\,17\.5rem\)\] {
  width: min(100%, 17.5rem);
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-max {
  width: max-content;
}

.w-screen {
  width: 100vw;
}

.min-w-52 {
  min-width: 13rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-\[12\.5rem\] {
  min-width: 12.5rem;
}

.min-w-\[13\.5rem\] {
  min-width: 13.5rem;
}

.min-w-\[14\.75rem\] {
  min-width: 14.75rem;
}

.min-w-\[14rem\] {
  min-width: 14rem;
}

.min-w-\[60\%\] {
  min-width: 60%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3 {
  max-width: .75rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-56 {
  max-width: 14rem;
}

.max-w-9\/12 {
  max-width: 75%;
}

.max-w-\[100vw\] {
  max-width: 100vw;
}

.max-w-\[14rem\] {
  max-width: 14rem;
}

.max-w-\[17rem\] {
  max-width: 17rem;
}

.max-w-\[18px\] {
  max-width: 18px;
}

.max-w-\[330px\] {
  max-width: 330px;
}

.max-w-\[40rem\] {
  max-width: 40rem;
}

.max-w-\[calc\(100vw-66px\)\] {
  max-width: calc(100vw - 66px);
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xs {
  max-width: 20rem;
}

.flex-1 {
  flex: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-shrink-0, .shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-full {
  --tw-translate-x: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-\[1px\] {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-0 {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-5 {
  --tw-translate-x: 1.25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-1 {
  --tw-translate-y: .25rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-2 {
  --tw-translate-y: .5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-full {
  --tw-translate-y: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-180 {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-95 {
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: .5s ease-in-out fade-in;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-default {
  cursor: default;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.select-none {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.resize {
  resize: both;
}

.appearance-none {
  -ms-appearance: none;
  appearance: none;
}

.break-inside-avoid {
  break-inside: avoid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-2-auto {
  grid-template-columns: repeat(2, minmax(0, auto));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.content-center {
  align-content: center;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-2\.5 {
  gap: .625rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-\[8px\] {
  gap: 8px;
}

.gap-px {
  gap: 1px;
}

.gap-x-1 {
  column-gap: .25rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-x-8 {
  column-gap: 2rem;
}

.gap-x-\[6\.35rem\] {
  column-gap: 6.35rem;
}

.gap-y-0\.5 {
  row-gap: .125rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.375rem * var(--tw-space-y-reverse));
}

.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.75rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}

.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}

.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity, 1));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity, 1));
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.\!overflow-visible {
  overflow: visible !important;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.hyphens-auto {
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.break-words {
  overflow-wrap: break-word;
}

.break-keep {
  word-break: keep-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-y-2 {
  border-top-width: 2px;
  border-bottom-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-l-6 {
  border-left-width: 6px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-none {
  border-style: none;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity, 1));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity, 1));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity, 1));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity, 1));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.border-primary-green {
  --tw-border-opacity: 1;
  border-color: rgba(55, 129, 149, var(--tw-border-opacity, 1));
}

.border-primary-green-dark {
  --tw-border-opacity: 1;
  border-color: rgba(39, 89, 104, var(--tw-border-opacity, 1));
}

.border-primary-lighter {
  --tw-border-opacity: 1;
  border-color: rgba(152, 52, 77, var(--tw-border-opacity, 1));
}

.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity, 1));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity, 1));
}

.border-secondary-darker {
  --tw-border-opacity: 1;
  border-color: rgba(209, 205, 205, var(--tw-border-opacity, 1));
}

.border-secondary-light {
  --tw-border-opacity: 1;
  border-color: rgba(247, 245, 245, var(--tw-border-opacity, 1));
}

.border-transparent {
  border-color: #0000;
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity, 1));
}

.border-b-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.border-b-zinc-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(212, 212, 216, var(--tw-border-opacity, 1));
}

.border-l-primary {
  --tw-border-opacity: 1;
  border-left-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.border-l-zinc-300 {
  --tw-border-opacity: 1;
  border-left-color: rgba(212, 212, 216, var(--tw-border-opacity, 1));
}

.border-r-primary {
  --tw-border-opacity: 1;
  border-right-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.border-r-zinc-300 {
  --tw-border-opacity: 1;
  border-right-color: rgba(212, 212, 216, var(--tw-border-opacity, 1));
}

.border-t-primary {
  --tw-border-opacity: 1;
  border-top-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.border-t-zinc-300 {
  --tw-border-opacity: 1;
  border-top-color: rgba(212, 212, 216, var(--tw-border-opacity, 1));
}

.\!bg-transparent {
  background-color: #0000 !important;
}

.bg-\[\#27596838\] {
  background-color: #27596838;
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity, 1));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity, 1));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity, 1));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity, 1));
}

.bg-custom-area-ratio {
  --tw-bg-opacity: 1;
  background-color: rgba(193, 99, 55, var(--tw-bg-opacity, 1));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity, 1));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity, 1));
}

.bg-esg-green {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 170, 0, var(--tw-bg-opacity, 1));
}

.bg-excel-green {
  --tw-bg-opacity: 1;
  background-color: rgba(32, 114, 69, var(--tw-bg-opacity, 1));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity, 1));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity, 1));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity, 1));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity, 1));
}

.bg-gray-500\/75 {
  background-color: #6b7280bf;
}

.bg-iu-green {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 107, 85, var(--tw-bg-opacity, 1));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(130, 44, 66, var(--tw-bg-opacity, 1));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(105, 36, 53, var(--tw-bg-opacity, 1));
}

.bg-primary-green {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 129, 149, var(--tw-bg-opacity, 1));
}

.bg-primary-green-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(39, 89, 104, var(--tw-bg-opacity, 1));
}

.bg-primary-lighter {
  --tw-bg-opacity: 1;
  background-color: rgba(152, 52, 77, var(--tw-bg-opacity, 1));
}

.bg-primary-yellow {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 206, 103, var(--tw-bg-opacity, 1));
}

.bg-primary\/0 {
  background-color: #822c4200;
}

.bg-primary\/10 {
  background-color: #822c421a;
}

.bg-primary\/20 {
  background-color: #822c4233;
}

.bg-primary\/30 {
  background-color: #822c424d;
}

.bg-primary\/40 {
  background-color: #822c4266;
}

.bg-primary\/50 {
  background-color: #822c4280;
}

.bg-primary\/60 {
  background-color: #822c4299;
}

.bg-primary\/70 {
  background-color: #822c42b3;
}

.bg-primary\/80 {
  background-color: #822c42cc;
}

.bg-primary\/90 {
  background-color: #822c42e6;
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity, 1));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity, 1));
}

.bg-secondary-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 220, 220, var(--tw-bg-opacity, 1));
}

.bg-secondary-darker {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
}

.bg-secondary-light {
  --tw-bg-opacity: 1;
  background-color: rgba(247, 245, 245, var(--tw-bg-opacity, 1));
}

.bg-teal-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(13, 148, 136, var(--tw-bg-opacity, 1));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(212, 212, 216, var(--tw-bg-opacity, 1));
}

.bg-opacity-75 {
  --tw-bg-opacity: .75;
}

.fill-current {
  fill: currentColor;
}

.object-cover {
  object-fit: cover;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-4 {
  padding: 1rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[0\.4rem\] {
  padding: .4rem;
}

.p-\[1\.5px\] {
  padding: 1.5px;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-\[0\.1\] {
  padding-top: .1px;
  padding-bottom: .1px;
}

.py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.\!pl-0 {
  padding-left: 0 !important;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-0 {
  padding-left: 0;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-\[1\.2rem\] {
  padding-left: 1.2rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[0\.2rem\] {
  padding-top: .2rem;
}

.pt-\[3px\] {
  padding-top: 3px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.align-middle {
  vertical-align: middle;
}

.font-sans {
  font-family: InterVariable, Inter, sans-serif;
}

.\!text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[0\.65rem\] {
  font-size: .65rem;
}

.text-\[0\.7em\] {
  font-size: .7em;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-4 {
  line-height: 1rem;
}

.leading-4\.5 {
  line-height: 1.1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-\[1\.4rem\] {
  line-height: 1.4rem;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.\!text-primary {
  --tw-text-opacity: 1 !important;
  color: rgba(130, 44, 66, var(--tw-text-opacity, 1)) !important;
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity, 1));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity, 1));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity, 1));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity, 1));
}

.text-custom-area-ratio {
  --tw-text-opacity: 1;
  color: rgba(193, 99, 55, var(--tw-text-opacity, 1));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity, 1));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity, 1));
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity, 1));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity, 1));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity, 1));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity, 1));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity, 1));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity, 1));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

.text-marker-blue {
  --tw-text-opacity: 1;
  color: rgba(0, 73, 165, var(--tw-text-opacity, 1));
}

.text-marker-gray {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.text-marker-green {
  --tw-text-opacity: 1;
  color: rgba(0, 140, 124, var(--tw-text-opacity, 1));
}

.text-marker-orange {
  --tw-text-opacity: 1;
  color: rgba(226, 132, 19, var(--tw-text-opacity, 1));
}

.text-marker-red {
  --tw-text-opacity: 1;
  color: rgba(152, 52, 77, var(--tw-text-opacity, 1));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgba(130, 44, 66, var(--tw-text-opacity, 1));
}

.text-primary-dark {
  --tw-text-opacity: 1;
  color: rgba(105, 36, 53, var(--tw-text-opacity, 1));
}

.text-primary-green {
  --tw-text-opacity: 1;
  color: rgba(55, 129, 149, var(--tw-text-opacity, 1));
}

.text-primary-lighter {
  --tw-text-opacity: 1;
  color: rgba(152, 52, 77, var(--tw-text-opacity, 1));
}

.text-primary-yellow {
  --tw-text-opacity: 1;
  color: rgba(244, 206, 103, var(--tw-text-opacity, 1));
}

.text-primary-yellow-dark {
  --tw-text-opacity: 1;
  color: rgba(134, 100, 9, var(--tw-text-opacity, 1));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity, 1));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity, 1));
}

.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity, 1));
}

.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity, 1));
}

.text-secondary-darkest {
  --tw-text-opacity: 1;
  color: rgba(87, 87, 87, var(--tw-text-opacity, 1));
}

.text-teal-600 {
  --tw-text-opacity: 1;
  color: rgba(13, 148, 136, var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
}

.underline {
  text-decoration-line: underline;
}

.placeholder-primary::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 44, 66, var(--tw-placeholder-opacity, 1));
}

.placeholder-primary::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(130, 44, 66, var(--tw-placeholder-opacity, 1));
}

.placeholder-red-300::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity, 1));
}

.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity, 1));
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.opacity-30 {
  opacity: .3;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.\!shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-2 {
  outline-width: 2px;
}

.outline-offset-\[-2px\] {
  outline-offset: -2px;
}

.outline-primary {
  outline-color: #822c42;
}

.outline-primary-green-dark {
  outline-color: #275968;
}

.outline-transparent {
  outline-color: #0000;
}

.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity, 1));
}

.ring-black\/5 {
  --tw-ring-color: #0000000d;
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px #0000001a) drop-shadow(0 1px 1px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.drop-shadow-\[0px_0px_8px_\#00000040\] {
  --tw-drop-shadow: drop-shadow(0px 0px 8px #00000040);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.contain-strict {
  contain: strict;
}

.\@container {
  container-type: inline-size;
}

.\[font-variant-ligatures\:no-contextual\] {
  font-variant-ligatures: no-contextual;
}

.\[key\:value\] {
  key: value;
}

.react-select input, .react-select input:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-width: 0;
  outline: 2px solid #0000;
}

.slick-prev:before, .slick-next:before {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity, 1));
  opacity: 1;
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
  padding: .5rem;
  display: inline-block;
}

.slick-disabled {
  opacity: 1;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

body {
  --toastify-icon-color-success: #10b981;
}

.sr-only {
  display: none;
}

:not(:is(.leaflet-editing-icon, .search-location-marker, .marker-cluster-custom)).leaflet-marker-icon {
  background: none !important;
  border: none !important;
  outline: none !important;
}

.cluster-marker {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: flex;
}

.leaflet-draw-tooltip-visible {
  visibility: visible;
}

.leaflet-draw-tooltip-visible:before {
  left: -1px;
}

@keyframes fade {
  from {
    opacity: .8;
  }
}

.blink {
  animation: 1s infinite alternate fade;
}

@-webkit-keyframes oscillate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
    filter: none;
  }

  100% {
    opacity: 0;
    transform: scale(.9);
  }
}

@-moz-keyframes oscillate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
    filter: none;
  }

  100% {
    opacity: 0;
    transform: scale(.9);
  }
}

@keyframes oscillate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }

  100% {
    opacity: 0;
    transform: scale(.9);
  }
}

.search-location-marker {
  z-index: 581;
  background-color: #822c42;
  border-radius: 100%;
  outline: none;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  transform: translate3d(800px, 581px, 0);
  box-shadow: 1px 1px 8px #000000bf;
}

.search-location-marker:after {
  content: "";
  border-radius: 100%;
  width: 300%;
  height: 300%;
  margin: -100% 0 0 -100%;
  animation: 1.2s ease-out 1.1s infinite oscillate;
  position: absolute;
  box-shadow: 0 0 6px 2px #822c42;
}

.gutter {
  background-position: 50%;
  background-repeat: no-repeat;
}

.gutter.gutter-horizontal {
  cursor: col-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
}

html {
  overscroll-behavior-y: none;
  -webkit-print-color-adjust: exact;
}

html, html > body {
  z-index: 0 !important;
}

@media (width <= 1023px) {
  html, html > body {
    overflow-y: auto !important;
  }
}

html > body {
  width: 100%;
  height: 100%;
  position: absolute;
}

@media (width >= 1024px) {
  html > body {
    width: auto;
    min-height: 100vh;
    position: relative;
  }
}

html > body :not(#scrollable-table) {
  overscroll-behavior-y: none;
}

html > body > #root {
  flex-direction: column;
  height: 100%;
  display: flex;
}

html > body > #root > div.bg-secondary-light:not(:is(#liste, #statistik)) {
  height: 100%;
}

@media (width >= 1024px) {
  html > body > #root > div.bg-secondary-light:not(:is(#liste, #statistik)) {
    height: auto;
  }
}

html > body > #root > div#transaktionen, html > body > #root > div#share-deals, html > body > #root > div#insolvenzen, html > body > #root > div#merklisten, html > body > #root > div#project-details {
  height: 100% !important;
}

html > body > #root > div#statistik {
  height: auto;
}

@media (width >= 1024px) {
  html > body > #root > div#statistik {
    height: 100%;
  }
}

html > body > #root > div#transaktionen, html > body > #root > div#share-deals, html > body > #root > div#insolvenzen, html > body > #root > div#merklisten, html > body > #root > div#merkliste, html > body > #root > div#project-details {
  min-height: calc(100vh - 3rem) !important;
}

html > body:has(div#transaktionen, div#share-deals, div#insolvenzen, div#merklisten, div#merkliste, div#project-details) {
  height: auto !important;
}

html > body:not(:is(div#root > :has(div#karte), :has(div#liste))) {
  min-height: 100vh;
}

html > body:has(div#root > div#merklisten, div#merkliste, div#project-details) {
  width: 100%;
}

html:has(body > div#root > div#statistik) {
  touch-action: none !important;
}

@page {
  margin: 1cm;
}

@media print {
  .leaflet-control {
    display: none;
  }
}

div:has( > div > div.gm-iv-container.gm-iv-small-container) {
  --tw-translate-y: 52px;
  --tw-translate-x: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (width >= 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (width >= 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (width >= 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (width >= 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (width >= 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  @media (width >= 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
}

.placeholder\:text-gray-400::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
}

.before\:block:before {
  content: var(--tw-content);
  display: block;
}

.before\:h-0:before {
  content: var(--tw-content);
  height: 0;
}

.before\:overflow-hidden:before {
  content: var(--tw-content);
  overflow: hidden;
}

.before\:font-bold:before {
  content: var(--tw-content);
  font-weight: 700;
}

.before\:content-\[attr\(title\)\]:before {
  --tw-content: attr(title);
  content: var(--tw-content);
}

.first\:mt-0:first-child {
  margin-top: 0;
}

.first\:border-t-0:first-child {
  border-top-width: 0;
}

.first\:pt-0:first-child {
  padding-top: 0;
}

.last\:border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:pb-0:last-child {
  padding-bottom: 0;
}

.last\:pb-8:last-child {
  padding-bottom: 2rem;
}

@media (hover: hover) and (pointer: fine) {
  .hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity, 1));
  }

  .hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity, 1));
  }

  .hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-custom-area-ratio-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(184, 76, 26, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-excel-green-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(19, 74, 44, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(130, 44, 66, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-primary-dark:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 36, 53, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-primary-lighter:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(152, 52, 77, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-secondary-darker:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 205, 205, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
  }

  .hover\:bg-opacity-5:hover {
    --tw-bg-opacity: .05;
  }

  .hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity, 1));
  }

  .hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity, 1));
  }

  .hover\:text-primary:hover {
    --tw-text-opacity: 1;
    color: rgba(130, 44, 66, var(--tw-text-opacity, 1));
  }

  .hover\:text-primary-dark:hover {
    --tw-text-opacity: 1;
    color: rgba(105, 36, 53, var(--tw-text-opacity, 1));
  }

  .hover\:text-primary-light:hover {
    --tw-text-opacity: 1;
    color: rgba(234, 194, 204, var(--tw-text-opacity, 1));
  }

  .hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity, 1));
  }

  .hover\:underline:hover {
    text-decoration-line: underline;
  }

  .hover\:no-underline:hover {
    text-decoration-line: none;
  }
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:border-custom-area-ratio:focus {
  --tw-border-opacity: 1;
  border-color: rgba(193, 99, 55, var(--tw-border-opacity, 1));
}

.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity, 1));
}

.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity, 1));
}

.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity, 1));
}

.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity, 1));
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgba(130, 44, 66, var(--tw-border-opacity, 1));
}

.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity, 1));
}

.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
}

.focus\:bg-opacity-5:focus {
  --tw-bg-opacity: .05;
}

.focus\:text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity, 1));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-custom-area-ratio:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(193, 99, 55, var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity, 1));
}

.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity, 1));
}

.focus\:ring-primary:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(130, 44, 66, var(--tw-ring-opacity, 1));
}

.focus\:ring-primary-lighter:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(152, 52, 77, var(--tw-ring-opacity, 1));
}

.focus\:ring-primary-yellow:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 206, 103, var(--tw-ring-opacity, 1));
}

.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity, 1));
}

.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}

.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity, 1));
}

.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-custom-area-ratio:focus {
  --tw-ring-offset-color: #c16337;
}

.focus-visible\:ring-2:focus-visible {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:ring-white:focus-visible {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity, 1));
}

.focus-visible\:ring-opacity-75:focus-visible {
  --tw-ring-opacity: .75;
}

.active\:bg-black:active {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity, 1));
}

.active\:bg-opacity-5:active {
  --tw-bg-opacity: .05;
}

.disabled\:cursor-default:disabled {
  cursor: default;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:cursor-wait:disabled {
  cursor: wait;
}

.disabled\:opacity-50:disabled {
  opacity: .5;
}

.disabled\:shadow-none:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.disabled\:grayscale:disabled {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (hover: hover) and (pointer: fine) {
  .group:hover .group-hover\:border-primary-dark {
    --tw-border-opacity: 1;
    border-color: rgba(105, 36, 53, var(--tw-border-opacity, 1));
  }

  .group:hover .group-hover\:bg-primary-light {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 194, 204, var(--tw-bg-opacity, 1));
  }

  .group:hover .group-hover\:text-primary-dark {
    --tw-text-opacity: 1;
    color: rgba(105, 36, 53, var(--tw-text-opacity, 1));
  }

  .group:hover .group-hover\:text-primary-light {
    --tw-text-opacity: 1;
    color: rgba(234, 194, 204, var(--tw-text-opacity, 1));
  }

  .group:hover .group-hover\:underline {
    text-decoration-line: underline;
  }
}

.data-\[closed\]\:translate-x-full[data-closed] {
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:translate-y-4[data-closed] {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[closed\]\:opacity-0[data-closed] {
  opacity: 0;
}

@container (width >= 702px) {
  .\@\[702px\]\:flex-row {
    flex-direction: row;
  }
}

@container (width >= 1080px) {
  .\@\[1080px\]\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .\@\[1080px\]\:flex {
    display: flex;
  }

  .\@\[1080px\]\:hidden {
    display: none;
  }

  .\@\[1080px\]\:h-\[130px\] {
    height: 130px;
  }

  .\@\[1080px\]\:w-2\/6 {
    width: 33.3333%;
  }

  .\@\[1080px\]\:w-4\/6 {
    width: 66.6667%;
  }

  .\@\[1080px\]\:flex-row {
    flex-direction: row;
  }

  .\@\[1080px\]\:items-end {
    align-items: flex-end;
  }

  .\@\[1080px\]\:gap-0 {
    gap: 0;
  }

  .\@\[1080px\]\:pb-\[13px\] {
    padding-bottom: 13px;
  }

  .\@\[1080px\]\:pr-0 {
    padding-right: 0;
  }

  .\@\[1080px\]\:pt-\[18px\] {
    padding-top: 18px;
  }

  .group[data-collapsed="false"] .group-data-\[collapsed\=false\]\:\@\[1080px\]\:text-right {
    text-align: right;
  }
}

.ui-open\:outline-primary[data-headlessui-state~="open"] {
  outline-color: #822c42;
}

.ui-open\:outline-primary-green-dark[data-headlessui-state~="open"] {
  outline-color: #275968;
}

:where([data-headlessui-state~="open"]) .ui-open\:outline-primary {
  outline-color: #822c42;
}

:where([data-headlessui-state~="open"]) .ui-open\:outline-primary-green-dark {
  outline-color: #275968;
}

.ui-active\:bg-gray-100[data-headlessui-state~="active"] {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
}

.ui-active\:text-gray-900[data-headlessui-state~="active"] {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

:where([data-headlessui-state~="active"]) .ui-active\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity, 1));
}

:where([data-headlessui-state~="active"]) .ui-active\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:size-10 {
    width: 2.5rem;
    height: 2.5rem;
  }

  .sm\:size-7 {
    width: 1.75rem;
    height: 1.75rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:max-h-screen-80 {
    max-height: 80vh;
  }

  .sm\:w-140 {
    width: 28rem;
  }

  .sm\:w-5\/12 {
    width: 41.6667%;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-x-2 {
    --tw-translate-x: .5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:gap-4 {
    gap: 1rem;
  }

  .sm\:gap-x-4 {
    column-gap: 1rem;
  }

  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity, 1));
  }

  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity, 1));
  }

  .sm\:rounded {
    border-radius: .25rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .sm\:duration-200 {
    transition-duration: .2s;
  }

  .sm\:data-\[closed\]\:translate-y-0[data-closed] {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:data-\[closed\]\:scale-95[data-closed] {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

@media (width >= 768px) {
  .md\:absolute {
    position: absolute;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid {
    display: grid;
  }

  .md\:hidden {
    display: none;
  }

  .md\:h-11 {
    height: 2.75rem;
  }

  .md\:max-h-\[38rem\] {
    max-height: 38rem;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:whitespace-nowrap {
    white-space: nowrap;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line;
  }
}

@media (width >= 1024px) {
  .lg\:-left-\[137px\] {
    left: -137px;
  }

  .lg\:right-1\/4 {
    right: 25%;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mr-44 {
    margin-right: -11rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-0\.5 {
    margin-top: .125rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:size-20 {
    width: 5rem;
    height: 5rem;
  }

  .lg\:size-5 {
    width: 1.25rem;
    height: 1.25rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-\[1\.875rem\] {
    height: 1.875rem;
  }

  .lg\:h-\[62px\] {
    height: 62px;
  }

  .lg\:h-\[calc\(100vh-3rem\)\] {
    height: calc(100vh - 3rem);
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:max-h-\[calc\(100vh-3rem\)\] {
    max-height: calc(100vh - 3rem);
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-140 {
    width: 28rem;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-9, .lg\:w-\[2\.25rem\] {
    width: 2.25rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .lg\:max-w-prose {
    max-width: 65ch;
  }

  .lg\:shrink-0 {
    flex-shrink: 0;
  }

  .lg\:grow {
    flex-grow: 1;
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:gap-2 {
    gap: .5rem;
  }

  .lg\:gap-4 {
    gap: 1rem;
  }

  .lg\:gap-x-4 {
    column-gap: 1rem;
  }

  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:rounded {
    border-radius: .25rem;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-\[0\.3rem\] {
    padding: .3rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }

  .lg\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }
}

@media (width >= 1280px) {
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:mr-1 {
    margin-right: .25rem;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mt-1\.5 {
    margin-top: .375rem;
  }

  .xl\:block {
    display: block;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-64 {
    height: 16rem;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }

  .xl\:min-w-\[60\%\] {
    min-width: 60%;
  }

  .xl\:max-w-\[60\%\] {
    max-width: 60%;
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:text-right {
    text-align: right;
  }

  .group[data-collapsed="false"] .group-data-\[collapsed\=false\]\:xl\:items-end {
    align-items: flex-end;
  }
}

@media (width >= 1536px) {
  .\32 xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .\32 xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .\32 xl\:ml-0 {
    margin-left: 0;
  }

  .\32 xl\:h-40 {
    height: 10rem;
  }

  .\32 xl\:h-64 {
    height: 16rem;
  }

  .\32 xl\:w-64 {
    width: 16rem;
  }

  .\32 xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\32 xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (width >= 400px) {
  .xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media print {
  .print\:ml-px {
    margin-left: 1px;
  }

  .print\:block {
    display: block;
  }

  .print\:flex {
    display: flex;
  }

  .print\:hidden {
    display: none;
  }

  .print\:h-auto {
    height: auto;
  }

  .print\:h-full {
    height: 100%;
  }

  .print\:max-h-\[50mm\] {
    max-height: 50mm;
  }

  .print\:w-full {
    width: 100%;
  }

  .print\:flex-row {
    flex-direction: row;
  }

  .print\:gap-8 {
    gap: 2rem;
  }

  .print\:gap-\[7px\] {
    gap: 7px;
  }

  .print\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }

  .print\:divide-neutral-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(115, 115, 115, var(--tw-divide-opacity, 1));
  }

  .print\:overflow-hidden {
    overflow: hidden;
  }

  .print\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .print\:border {
    border-width: 1px;
  }

  .print\:border-none {
    border-style: none;
  }

  .print\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity, 1));
  }

  .print\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity, 1));
  }

  .print\:p-0 {
    padding: 0;
  }

  .print\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .print\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .print\:text-center {
    text-align: center;
  }

  .print\:text-xs {
    font-size: .75rem;
    line-height: 1rem;
  }

  .print\:font-normal {
    font-weight: 400;
  }

  .print\:leading-4 {
    line-height: 1rem;
  }

  .print\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity, 1));
  }

  .print\:shadow-none {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

@container (width >= 1080px) {
  @media print {
    .\@\[1080px\]\:print\:w-full {
      width: 100%;
    }

    .group[data-collapsed="false"] .group-data-\[collapsed\=false\]\:\@\[1080px\]\:print\:text-center {
      text-align: center;
    }
  }
}
/*# sourceMappingURL=index.473dcbf2.css.map */
